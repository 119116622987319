import { useEffect, useState } from "react";
import getEndpoints from "../app/endpoints";

function useKpis() {

    const [data, setData] = useState(undefined);
    
    useEffect(() => {
        loadData();
    }, [])


    const loadData = async () => {
        const ep = await getEndpoints();
        const response = await ep.kpis();
        const data = typeof response === 'string' ? JSON.parse(response) : response;
        console.log(data);
        setData(data);
    }

    return {
        data
    }

}

export default useKpis;