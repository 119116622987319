import React, { useEffect } from 'react'


import Plotly from 'plotly.js-dist';

function get_axis_range(data) {
    let min = 1000000;
    let max = -100000;

    for(let i = 0; i < data.length; ++i) {
        let item = data[i];
        if(item > max) max = item;
        if(item < min) min = item;
    }

    let final_min = min - min * 0.1;
    let final_max = max + max * 0.1;

    return [final_min, final_max];
}


function get_bar_layout(ydata) {
    return {
        showlegend: false,
        margin: {
            l: 30, r: 30, b: 30, t: 30
        },
        xaxis: {
            tickfont: {
                family: 'Anek Odia, sans-serif',
                size: 10,
                color: 'black'
            },
            type: 'category'
        },
        yaxis: {
            tickfont: {
                family: 'Anek Odia, sans-serif',
                size: 10,
                color: 'black'
            },
            autorange : false,
            range: get_axis_range(ydata)
        }
    }
}

const get_bar_colors = (kpinames) => {
    return kpinames.map(item => {
        if(item === "YEAR") {
            return '#355070'
        } else if (item === "MONTH") {
            return '#6D597A'
        } else if (item === "WEEK") {
            return '#B56576'
        } else if (item === "DATE_STR") {
            return '#E56B6f'
        }
    });
}


const drawKpiChart = (xdata, ydata, divName, kpinames) => {

    let data = [
        {
            x: xdata,
            y: ydata,
            type: 'bar',
            marker: {
                color: get_bar_colors(kpinames)
            }
        }
    ]


    Plotly.newPlot(divName, data, get_bar_layout(ydata), { responsive: true, displayModeBar: false });
    Plotly.Plots.resize(document.getElementById(divName));
}

function KpiBarChart({ xdata, ydata, divId, kpinames }) {

    useEffect(() => {
        drawKpiChart(xdata, ydata, divId, kpinames);
    }, []);

    return (
        <div className="kpiChartHolder">
            <div id={divId} style={{ height: 'inherit' }}>
            </div>
        </div>
    )
}

export default KpiBarChart