import React, { useEffect } from 'react';
import Plotly from 'plotly.js-dist';

function get_layout() {
    return {
        showlegend: true,      
        legend: {      
          x: 0.0,      
          xanchor: 'left',      
          y: 1.0,
          font: {    
            family: 'Anek Odia, sans-serif',    
            size: 10,    
            color: 'black'    
          },
          bgcolor: '#E2E2E244'
        },
        margin:{
          l:30,r:30,b:30,t:30
        },
        xaxis: {
            tickfont: {    
              family: 'Anek Odia, sans-serif',    
              size: 8,    
              color: 'black'    
            }
        },
        yaxis: {
            tickfont: {    
              family: 'Anek Odia, sans-serif',    
              size: 8,    
              color: 'black'    
            },
            range: [0, 10000]
        },
        yaxis2: {
            tickfont: {    
              family: 'Anek Odia, sans-serif',    
              size: 8,    
              color: 'black'    
            },
            overlaying: 'y',
            side: 'right',
            range: [0, 100]
        }

    }
}


const drawDayChart = (chartData) => {

    //console.log("chartData:", chartData);

    let traceNames = ['current_usage','solar', 'grid_use']

    var traces = [];

    traceNames.forEach(element => {
        traces.push({
            x: chartData["date_str"],
            y: chartData[element],
            type: 'scatter',
            name: element
        });
    });
    traces.push({
        x: chartData["date_str"],
        y: chartData['charge'],
        type: 'scatter',
        yaxis: 'y2',
        name: 'charge'
    });


    Plotly.newPlot('dayChart', traces, get_layout(), { responsive: true, displayModeBar: false });
    Plotly.Plots.resize(document.getElementById('dayChart'));
}

function DailyChart({chartData}) {

    useEffect(() => {
        drawDayChart(chartData);
    }, [chartData]);
    return (
        <div id="chartHolder">
            <div id="dayChart" style={{height: 'inherit'}}></div>
        </div>
    )
}

export default DailyChart