import React from 'react'
import AllTimeRecordHeader from './AllTimeRecordHeader'
import KpiBarChart from './KpiBarChart'

function Kpi({title, data, keyname}) {
  return (
    <div>
        <AllTimeRecordHeader title={title} />       
        <KpiBarChart xdata={data['KPI_VALUE']} ydata={data[keyname]} divId={keyname} kpinames={data['KPI_NAME']}/>
    </div>
  )
}

export default Kpi