import React from 'react'
import DailyChart from '../components/DailyChart';
import DateSelector from '../components/DateSelector'
import SummaryStats from '../components/SummaryStats';
import TitleHeader from '../components/TitleHeader'
import useDailyData from '../hooks/useDailyData';

function DayHistory() {

    const { chartData, dayStats, dateChanged, startDate } = useDailyData();

    return (
        <>
            <TitleHeader title="Historical - Daily" />
            <DateSelector startDate={startDate} dateChanged={dateChanged} />
            { dayStats && <SummaryStats data={dayStats} />}
            <TitleHeader title="Chart" />
            { chartData && <DailyChart chartData={chartData} /> }
        </>
    )
}

export default DayHistory