import React from 'react'
import useRecords from '../hooks/useRecords';
import AllTimeRecord from '../components/AllTimeRecord';
import TitleHeader from '../components/TitleHeader';



function Records() {

    const { data } = useRecords();
    return (
        <>
            <TitleHeader title="All Time Records" />
            { data && 
            <div>
                {
                    data.keys.map((item) => {
                        return <AllTimeRecord data={data.data[item]} key={item} />
                    })
                }
            </div>
            }
        </>
    )
}

export default Records