import { useState } from 'react';
import { useUserAuth } from '../context/UserAuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
    const { logIn } = useUserAuth();
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            await logIn(username, password);
            navigate("/");
        } catch {
            setError('Failed to log in');
        }
    };

    return (
        <div>
            <form onSubmit={handleSubmit} >
                <div className='login-form'>
                    <label>
                        Username:
                        <input type="text" name="username" value={username} onChange={(e) => setUsername(e.target.value)} />
                    </label>
                    <label>
                        Password:
                        <input type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                    </label>
                    <button type="submit" value="Submit">Login</button>
                    {error && <p className="error-message">{error}</p>}
                </div>
            </form>
        </div>
    );
};

export default Login;