import { useEffect, useState } from "react";
import getEndpoints from "../app/endpoints";

function useLiveData() {

    const [liveData, setLiveData] = useState(undefined);
    const [loading, setLoading] = useState(undefined);
    const [lastRefresh, setLastRefresh] = useState("");
    
    
    useEffect(() => {
        loadData();
    }, []);


    const loadData = async () => {
        setLoading(true);
        const ep = await getEndpoints();
        const data = await ep.liveData();
        setLiveData(data.live_data);
        setLoading(false);
        setLastRefresh((new Date()).toISOString())
    }

    return {
        liveData,
        lastRefresh,
        loading
    }

}

export default useLiveData;