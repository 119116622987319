import React from 'react'

function DayStat({title, value}) {
  return (
    <div>
        <div className="statHolder">
            <span className="statTitle">{title}</span>
            <span className="statValue">{value}</span>
        </div>
    </div>
  )
}

export default DayStat