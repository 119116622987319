import { useEffect, useState } from "react";
import getEndpoints from "../app/endpoints";

function useRecords() {

    const [data, setData] = useState(undefined);
    
    useEffect(() => {
        loadData();
    }, [])


    const loadData = async (date) => {
        const ep = await getEndpoints();
        const data = await ep.records();
        setData(data);
    }

    return {
        data
    }

}

export default useRecords;