import React from 'react'
import Kpi from '../components/Kpi';
import TitleHeader from '../components/TitleHeader';
import useKpis from '../hooks/useKpis';

const CHART_KEYS = [
    { key: "total_cost_bal", title: "Daily Cost €" },
    { key: "total_consumed", title: "Consumption kW" },
    { key: "total_solar_prod", title: "Solar Production kW" },
    { key: "total_from_grid", title: "Grid Use kW" },
    { key: "total_to_grid", title: "To Grid kW" },
    { key: "total_battery_use", title: "Battery Use kW" },
    { key: "total_efficiency", title: "Efficiency %" }
];

function Kpis() {

    const { data } = useKpis();

    return (
        <>

            <TitleHeader title="Good old KPIs" />
            {data &&
                <div>              
                    {
                        CHART_KEYS.map((item) => {
                            console.log(item.key)
                            return <Kpi key={item.key} title={item.title} keyname={item.key} data={data} />
                        })
                    } 
                </div>
            }
        </>
    )
}

export default Kpis