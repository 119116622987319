import React from 'react'
import DatePicker,{ registerLocale }  from "react-datepicker";
import de from 'date-fns/locale/es';
registerLocale('de', de);

function DateSelector({startDate, dateChanged}) {
  return (
    <div className='date-selector'>
        <DatePicker selected={startDate} onChange={(date) => dateChanged(date)} dateFormat="E, dd MMM yyyy" />
    </div>
  )
}

export default DateSelector