import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";


const ProtectedRoute = ({ children }) => {
  const { user, isLoading  } = useUserAuth();

  if (isLoading) {
    return <p>Loading..</p>
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default ProtectedRoute;