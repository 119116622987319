import React from 'react'
import AllTimeRecordHeader from './AllTimeRecordHeader'
import AllTimeRecordStat from './AllTimeRecordStat'

function AllTimeRecord({data}) {
  return (
    <div>
        <AllTimeRecordHeader title={data.title}/>
         <div className="records">
            <AllTimeRecordStat data={data.record.high} title="High" settings={data.settings} />
            <AllTimeRecordStat data={data.record.low} title="Low" settings={data.settings} />
        </div>
    </div>
  )
}

export default AllTimeRecord