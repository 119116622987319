import DailyChart from '../components/DailyChart';
import LiveStats from '../components/LiveStats'
import SummaryStats from '../components/SummaryStats';
import TitleHeader from '../components/TitleHeader';
import useDailyData from '../hooks/useDailyData';

function Home() {

    const { chartData, dayStats } = useDailyData();

    return (
        <>
            <LiveStats />
            {dayStats && <SummaryStats data={dayStats} />}
            <TitleHeader title="Consumption" />
            {dayStats && <DailyChart chartData={chartData} />}
        </>
    )
}

export default Home