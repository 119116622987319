import getApiWithToken from "./api";  


const responseBody = (response) => {
    return response.data;
}

const requests = {
    get: (api, url) => api.get(url).then(responseBody)
}


export default async function getEndpoints() {
    const api = await getApiWithToken();

    return {
        daily: (date_str) => requests.get(api, `/daily/${date_str}`),
        liveData: () => requests.get(api, `/live`),
        records: () => requests.get(api, `/records`),
        kpis: () => requests.get(api, `/kpis`)
    }
}
