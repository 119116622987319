import React from 'react'
import useLiveData from '../hooks/useLiveData';
import DayStat from './DayStat'
import TitleHeader from './TitleHeader';

function LiveStats() {
    
    const { liveData, lastRefresh, loading } = useLiveData();

    return (
        <>
            <TitleHeader title="Live" />
            {
                loading && <p>Loading...</p>
            }
            {
                liveData &&
                <div>
                    <DayStat title="Last Refresh:" value={lastRefresh} />
                    <DayStat title="Last Save:" value={liveData.read_date} />
                    <DayStat title="Solar Prod." value={liveData.current_solar_prod} />
                    <DayStat title="Usage" value={liveData.current_usage} />
                    <DayStat title="Charge" value={liveData.current_charge} />
                </div>
            }
        </>
    )
}

export default LiveStats;