import React from 'react';


export const formatted_stat = (data, settings) => {
    return `${data.toFixed(settings.fixed)}${settings.suffix}`
}

function AllTimeRecordStat({ data, settings, title }) {

    return (
        <div className="recordStatHolder">
            <div className='recordStatTitle'>{title}</div>
            <div className='recordStatValues'>
                <span className='recordStatValue'>{formatted_stat(data.value, settings)}</span>    
                <span className='recordStatValue'>({data.date_str})</span>    
            </div>
        </div>
    )
}

export default AllTimeRecordStat