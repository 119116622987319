import axios from "axios";
import { auth } from "../firebase-config";

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.response.use(
    response => response,
    error => {
        // Check if the error is due to a request cancellation
        if (axios.isCancel(error)) {
            return Promise.reject(error);
        }

        // Handle other errors
        if (error.response && error.response.status === 401) {
            // When a 401 error occurs, redirect to the login page.
            window.location.href = '/login';
        }
        return Promise.reject(error);
    }
);


export default async function getApiWithToken() {
    const currentUser = auth.currentUser;
    if (currentUser) {
        const token = await currentUser.getIdToken();
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
        delete axiosInstance.defaults.headers.common['Authorization'];
    }    

    axiosInstance.defaults.headers.common['Cache-Control'] = 'no-store';
    axiosInstance.defaults.headers.common['Content-Type'] = 'application/json';


    return axiosInstance;
}
