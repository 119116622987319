import React from 'react'
import { MdHome, MdToday, MdBarChart, MdExitToApp } from 'react-icons/md';
import { FaMedal } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { useUserAuth } from '../context/UserAuthContext';


function NavigationBar() {

    const { logOut } = useUserAuth();
    
    return (

        <div id="navbar" >
            <div className='navtitle'>Solar</div>
            <div id="nav-menu">
                <div className="menuIcon"><Link to="/"><MdHome /></Link></div>
                <div className="menuIcon"><Link to="/daily-hist"><MdToday /></Link></div>
                <div className="menuIcon"><Link to="/records"><FaMedal /></Link></div>
                <div className="menuIcon"><Link to="/kpis"><MdBarChart /></Link></div>
                <div className="menuIcon logout-button" onClick={() => logOut()}><MdExitToApp /></div>
            </div>
        </div>
    )
}

export default NavigationBar

