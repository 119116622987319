import React from 'react'
import DayStat from './DayStat'
import TitleHeader from './TitleHeader'

const stat_settings = [
    {key: 'total_efficiency', title: 'Efficiency', suffix: '%', fixed: 2},
    {key: 'total_consumed', title: 'Consumption', suffix: 'kW', fixed: 2},
    {key: 'total_solar_prod', title: 'Solar Prod.', suffix: 'kW', fixed: 2},
    {key: 'total_battery_use', title: 'Battery Use', suffix: 'kW', fixed: 2},
    {key: 'total_from_grid', title: 'Grid Use', suffix: 'kW', fixed: 2},
    {key: 'total_to_grid', title: 'To Grid', suffix: 'kW', fixed: 2},
]

const formatted_stat = (data, settings) => {
    return `${data.toFixed(settings.fixed)}${settings.suffix}`
}

function SummaryStats({ data }) {
    return (
        <>
            <TitleHeader title="Summary" />
            <div>
                {
                    stat_settings.map((item) => {
                        return <DayStat title={item.title} key={item.key} value={formatted_stat(data[item.key], item)} />
                    })
                }
            </div>
        </>
    )
}

export default SummaryStats