import { useEffect, useState } from "react";
import getEndpoints from "../app/endpoints";

function useDailyData() {

    const [startDate, setStartDate] = useState(new Date());
    const [dayStats, setDayStats] = useState(undefined);
    const [chartData, setChartData] = useState(undefined);

    
    useEffect(() => {
        dateChanged(new Date());
    }, [])

    const dateChanged = (newDate) => {
        setStartDate(newDate);
        loadData(newDate);
    }

    const loadData = async (date) => {
        const ep = await getEndpoints();
        const data = await ep.daily(date.toISOString());
        console.log(data);
        setDayStats(data.summary_stats);
        setChartData(data.chart_data);
    }

    return {
        dateChanged,
        chartData,
        dayStats,
        startDate
    }

}

export default useDailyData;