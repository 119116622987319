import NavigationBar from "./components/NavigationBar";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom";

import Home from "./pages/Home";
import DayHistory from "./pages/DayHistory";
import Records from "./pages/Records";
import Kpis from "./pages/Kpis";
import ProtectedRoute from "./components/ProtectedRoute";
import { useUserAuth } from "./context/UserAuthContext";
import Login from "./pages/Login";

function App() {
    const { user } = useUserAuth();
    return (
        <>
            <BrowserRouter  >
                <div id="app-holder">
                    {user && <NavigationBar />}
                    <div className="container">
                        <Routes>
                            <Route exact path="/daily-hist" element={<ProtectedRoute><DayHistory /></ProtectedRoute>} />
                            <Route exact path="/records" element={<ProtectedRoute><Records /></ProtectedRoute>} />
                            <Route exact path="/kpis" element={<ProtectedRoute><Kpis /></ProtectedRoute>} />
                            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                            <Route path='/login' element={<Login />} />
                        </Routes>
                    </div>
                </div>
            </BrowserRouter >
        </>
    );
}

export default App;
